// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slider-container {
    width: 100%;
    max-width: 1300px; /* Giới hạn chiều rộng tối đa là 900px */
    margin: 0 auto; /* Căn giữa slider */
    padding: 20px; /* Thêm khoảng cách xung quanh slider */
  }
  
  .slider-image {
    width: 1300px; /* Chiều rộng của hình ảnh là 900px */
    height: 200px; /* Chiều cao của hình ảnh là 200px */
    border-radius: 30px; /* Bo tròn góc hình ảnh */
    object-fit: cover; /* Đảm bảo hình ảnh không bị biến dạng */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Thêm bóng đổ nhẹ */
  }
  `, "",{"version":3,"sources":["webpack://./src/components/home/SliderHome.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,iBAAiB,EAAE,wCAAwC;IAC3D,cAAc,EAAE,oBAAoB;IACpC,aAAa,EAAE,uCAAuC;EACxD;;EAEA;IACE,aAAa,EAAE,qCAAqC;IACpD,aAAa,EAAE,oCAAoC;IACnD,mBAAmB,EAAE,yBAAyB;IAC9C,iBAAiB,EAAE,wCAAwC;IAC3D,wCAAwC,EAAE,qBAAqB;EACjE","sourcesContent":[".slider-container {\n    width: 100%;\n    max-width: 1300px; /* Giới hạn chiều rộng tối đa là 900px */\n    margin: 0 auto; /* Căn giữa slider */\n    padding: 20px; /* Thêm khoảng cách xung quanh slider */\n  }\n  \n  .slider-image {\n    width: 1300px; /* Chiều rộng của hình ảnh là 900px */\n    height: 200px; /* Chiều cao của hình ảnh là 200px */\n    border-radius: 30px; /* Bo tròn góc hình ảnh */\n    object-fit: cover; /* Đảm bảo hình ảnh không bị biến dạng */\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Thêm bóng đổ nhẹ */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
