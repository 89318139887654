// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
    background-color: #2c3e50;
    color: #ecf0f1;
    padding: 20px;
    text-align: center;
  }
  
  .footer-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
  
  .footer-logo {
    text-align: left;
    
  }
  
  .footer-logo-image {
    width: 80px;
    margin-bottom: 10px;
  }
  
  .footer-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex; /* Sắp xếp các phần tử con theo hàng ngang */
    justify-content: center; /* Căn giữa các phần tử theo chiều ngang */
  }
  
  .footer-links ul li {
    margin: 0 15px; /* Khoảng cách giữa các mục */
  }
  
  .footer-links ul li a {
    color: #ecf0f1;
    text-decoration: none;
  }
  
  .footer-links ul li a:hover {
    color: #3498db; /* Màu khi hover */
  }
  
  
  .footer-contact {
    text-align: right;
  }
  
  .footer-bottom {
    margin-top: 20px;
    border-top: 1px solid #ecf0f1;
    padding-top: 10px;
    font-size: 0.9em;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/footer/Footer.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,cAAc;IACd,aAAa;IACb,kBAAkB;EACpB;;EAEA;IACE,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,eAAe;EACjB;;EAEA;IACE,gBAAgB;;EAElB;;EAEA;IACE,WAAW;IACX,mBAAmB;EACrB;;EAEA;IACE,gBAAgB;IAChB,UAAU;IACV,SAAS;IACT,aAAa,EAAE,4CAA4C;IAC3D,uBAAuB,EAAE,0CAA0C;EACrE;;EAEA;IACE,cAAc,EAAE,6BAA6B;EAC/C;;EAEA;IACE,cAAc;IACd,qBAAqB;EACvB;;EAEA;IACE,cAAc,EAAE,kBAAkB;EACpC;;;EAGA;IACE,iBAAiB;EACnB;;EAEA;IACE,gBAAgB;IAChB,6BAA6B;IAC7B,iBAAiB;IACjB,gBAAgB;EAClB","sourcesContent":[".footer {\n    background-color: #2c3e50;\n    color: #ecf0f1;\n    padding: 20px;\n    text-align: center;\n  }\n  \n  .footer-container {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    flex-wrap: wrap;\n  }\n  \n  .footer-logo {\n    text-align: left;\n    \n  }\n  \n  .footer-logo-image {\n    width: 80px;\n    margin-bottom: 10px;\n  }\n  \n  .footer-links ul {\n    list-style: none;\n    padding: 0;\n    margin: 0;\n    display: flex; /* Sắp xếp các phần tử con theo hàng ngang */\n    justify-content: center; /* Căn giữa các phần tử theo chiều ngang */\n  }\n  \n  .footer-links ul li {\n    margin: 0 15px; /* Khoảng cách giữa các mục */\n  }\n  \n  .footer-links ul li a {\n    color: #ecf0f1;\n    text-decoration: none;\n  }\n  \n  .footer-links ul li a:hover {\n    color: #3498db; /* Màu khi hover */\n  }\n  \n  \n  .footer-contact {\n    text-align: right;\n  }\n  \n  .footer-bottom {\n    margin-top: 20px;\n    border-top: 1px solid #ecf0f1;\n    padding-top: 10px;\n    font-size: 0.9em;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
