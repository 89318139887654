// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.booking-container {
    max-width: 400px;
    margin: auto;
    padding: 2rem;
    background: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .booking-container h2 {
    text-align: center;
    color: #4CAF50;
  }
  
  .form-group {
    margin-bottom: 1rem;
  }
  
  .form-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 0.5rem;
    color: #333;
  }
  
  .form-group input {
    width: 100%;
    padding: 0.8rem;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .booking-button {
    width: 100%;
    padding: 0.8rem;
    background-color: #4CAF50;
    color: white;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .booking-button:hover {
    background-color: #45a049;
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/appointment/BookingAppointment.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,YAAY;IACZ,aAAa;IACb,iBAAiB;IACjB,kBAAkB;IAClB,wCAAwC;EAC1C;;EAEA;IACE,kBAAkB;IAClB,cAAc;EAChB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,cAAc;IACd,iBAAiB;IACjB,qBAAqB;IACrB,WAAW;EACb;;EAEA;IACE,WAAW;IACX,eAAe;IACf,sBAAsB;IACtB,kBAAkB;EACpB;;EAEA;IACE,WAAW;IACX,eAAe;IACf,yBAAyB;IACzB,YAAY;IACZ,eAAe;IACf,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,iCAAiC;EACnC;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":[".booking-container {\n    max-width: 400px;\n    margin: auto;\n    padding: 2rem;\n    background: white;\n    border-radius: 8px;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n  }\n  \n  .booking-container h2 {\n    text-align: center;\n    color: #4CAF50;\n  }\n  \n  .form-group {\n    margin-bottom: 1rem;\n  }\n  \n  .form-group label {\n    display: block;\n    font-weight: bold;\n    margin-bottom: 0.5rem;\n    color: #333;\n  }\n  \n  .form-group input {\n    width: 100%;\n    padding: 0.8rem;\n    border: 1px solid #ccc;\n    border-radius: 5px;\n  }\n  \n  .booking-button {\n    width: 100%;\n    padding: 0.8rem;\n    background-color: #4CAF50;\n    color: white;\n    font-size: 1rem;\n    border: none;\n    border-radius: 5px;\n    cursor: pointer;\n    transition: background-color 0.3s;\n  }\n  \n  .booking-button:hover {\n    background-color: #45a049;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
